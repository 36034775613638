<template>
    <div class="page page--contact">
        <ProjectPage />     
    </div>
</template>


<script>
import ProjectPage from '@/components/pages/Project.vue'

export default {    
  components: {    
    ProjectPage    
  }  
}
</script>