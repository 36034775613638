<template>
    <section class="project page" id="sectionProject">
        <b-container fluid="xl">            
            <b-row>
                <b-col cols="12">
                    <div class="project__right-block">
                        <div class="project__block-top">                                                                               
                            <div class="project__text ">
                                <h2 class="animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up')" v-html="tr('project_page_title')"></h2>
                                <div class="project__lead animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-1')">
                                    {{tr('project_page_lead')}}
                                </div>   
                                <div class="mainCarousel-wrap animateScroll" :class="[selectedLanguage === 'hu' ? 'carousel--hu' : '',  selectedLanguage === 'en' ?'carousel--en' : '']"  v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-down', 'delay-2')">                                
                                    <OwlCarousel  :items="1" :dots="false"  :loop="true" :nav="true">
                                            <div class="" v-for="(slide, sindex) in slides" :key="sindex">
                                                <img :src="'/pictures/projectpage/' + slide" class="img-fluid"  />                            
                                            </div> 
                                    </OwlCarousel>
                                </div>
                                <p class="animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-2')">{{tr('project_page_text_1')}}</p>
                                <p class="animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-2')">
                                {{tr('project_page_text_2')}}
                                </p>
                                <p class="animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-3')">
                                {{tr('project_page_text_3')}}
                                </p>
                                 <p class="animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-3')">
                                {{tr('project_page_text_4')}}
                                </p>
                                        
                                <p class="animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-3')">
                               {{tr('project_page_text_5')}}
                                </p>
                                <p class="animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-3')">
                                    {{tr('project_page_text_6')}}
                                </p>
                                <p class="animateScroll project__date project__text" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-3')">{{tr('project_page_text_7')}}</p>                              
                            </div>                             
                        </div>                         
                    </div>                                                  
                </b-col>                  
            </b-row>            
        </b-container>      
        <Partners />  
    </section>
</template>


<script>
import OwlCarousel from 'vue-owl-carousel'
import Partners from '@/components/Partners.vue'

export default {
    name: 'Project',
    components:{
        OwlCarousel,
        Partners
    },
    data(){
        return{               
            slides:[
                'gallery-03.jpg',
                'gallery-04.jpg',
                'gallery-02.jpg',
                'gallery-05.jpg',
                'gallery-06.jpg',
                'gallery-07.jpg',
                'gallery-01.jpg'
            ],           
        }
    }  
}
</script>
